import * as React from 'react';
import flagsmith, { IFlags } from 'flagsmith';

export interface FlagsmithContextType {
  flags?: IFlags;
  hasFeature: typeof flagsmith.hasFeature;
  getValue: typeof flagsmith.getValue;
  getTrait: typeof flagsmith.getTrait;
  setTrait: typeof flagsmith.setTrait;
  setTraits: typeof flagsmith.setTraits;
}

export const FlagsmithContext = React.createContext<FlagsmithContextType>({
  flags: undefined,
  hasFeature: flagsmith.hasFeature,
  getValue: flagsmith.getValue,
  getTrait: flagsmith.getTrait,
  setTrait: flagsmith.setTrait,
  setTraits: flagsmith.setTraits,
});

export const FlagsmithProvider: React.FC<{
  identity?: string;
  environmentID?: string;
  cacheFlags?: boolean;
  refreshInterval?: number;
  flagsmith?: typeof flagsmith;
}> = ({
  identity,
  environmentID,
  cacheFlags = true,
  refreshInterval,
  children = null,
}) => {
  const [flags, setFlags] = React.useState<IFlags | undefined>(undefined);
  const mounted = React.useRef(true);

  React.useEffect(() => {
    if (!environmentID) return;

    if (identity) {
      flagsmith.identify(identity);
    }

    flagsmith.init({
      environmentID,
      cacheFlags,
      onChange: (_oldFlags, params) => {
        const { isFromServer, flagsChanged, traitsChanged } = params;
        if (isFromServer && (flagsChanged || traitsChanged)) {
          if (mounted.current) setFlags(flagsmith.getAllFlags());
        }
      },
    });

    if (refreshInterval) {
      flagsmith.startListening(refreshInterval);

      return () => {
        flagsmith.stopListening();
        mounted.current = false;
      };
    }

    return () => {
      mounted.current = false;
    };
  }, [identity, environmentID, cacheFlags, refreshInterval]);

  return (
    <FlagsmithContext.Provider
      value={{
        flags,
        hasFeature: flagsmith.hasFeature,
        getValue: flagsmith.getValue,
        getTrait: flagsmith.getTrait,
        setTrait: flagsmith.setTrait,
        setTraits: flagsmith.setTraits,
      }}
    >
      {children}
    </FlagsmithContext.Provider>
  );
};
