import * as React from 'react';
import { useFlagsmithFeature } from './useFlagsmith';

export const Feature: React.FC<{
  id: string;
  fallback?: React.ReactNode;
  children?: React.ReactNode | ((enabled: boolean) => React.ReactNode);
  render?: (enabled: boolean) => React.ReactNode;
}> = ({ id, fallback, children = null, render }) => {
  const enabled = useFlagsmithFeature(id);

  if (typeof render === 'function') {
    return <>{render(!!enabled)}</>;
  }

  if (!enabled) {
    return <>{fallback}</>;
  }

  return <>{children}</>;
};
