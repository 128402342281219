import { useContext } from 'react';
import { FlagsmithContext } from './FlagsmithContext';

export const useFlagsmith = () => {
  const context = useContext(FlagsmithContext);

  return context;
};

export const useFlagsmithFeature = (key: string) => {
  const flagsmith = useFlagsmith();

  if (typeof flagsmith.hasFeature === 'undefined') {
    return undefined;
  }

  return flagsmith.hasFeature(key);
};

export const useFlagsmithValue = (key: string) => {
  const flagsmith = useFlagsmith();

  if (typeof flagsmith.getValue === 'undefined') {
    return undefined;
  }

  return flagsmith.getValue(key);
};

export const useFlagsmithTrait = (
  key: string,
): [
  string | number | boolean | undefined,
  (value: string | number | boolean) => void,
] => {
  const flagsmith = useFlagsmith();

  if (typeof flagsmith.getTrait === 'undefined') {
    return [undefined, () => {}];
  }

  return [
    flagsmith.getTrait(key),
    (value) => {
      flagsmith.setTrait(key, value);
    },
  ];
};
